import endpoints from "./endpoints";
import apiConfig from "../config/api";
import http from "./http";
import store from "../store/index";

const { getAllSales, addSale, editSale, deleteSale, getSale } = endpoints;
const { baseURL } = apiConfig;

const ElectronicRegisterSalesOffer = {
  async handleDeleteSale(id) {
    const getDeleteSaleUrl = `${baseURL}${deleteSale.GET(id)}`;
    return await http
      .delete(
        getDeleteSaleUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleEditSale(sale) {
    const getEditSaleUrl = `${baseURL}${editSale.GET(sale.id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .put(
        getEditSaleUrl,
        {
          id: sale.id,
          cityHallId: "1",
          sellerAddress: sale.sellerAddress,
          surface: sale.surface,
          category: sale.category,
          price: sale.price,
          tarla: sale.tarla,
          parcela: sale.parcela,
          procesVerbalProcedura: sale.procesVerbalProcedura,
          procesVerbalFinalizare: sale.procesVerbalFinalizare,
          buyer: sale.buyer,
          registrationDate: sale.registrationDate,
          landRegistryNumber: sale.landRegistryNumber,
          sellerFirstNameLastName: sale.sellerFirstNameLastName,
          username: sale.username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleAddSale(sale) {
    const getAddSaleOfferUrl = `${baseURL}${addSale.GET}`;

    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;

    return await http
      .post(
        getAddSaleOfferUrl,
        {
          cityHallId: "1",
          sellerAddress: sale.sellerAddress,
          surface: sale.surface,
          category: sale.category,
          price: sale.price,
          tarla: sale.tarla,
          parcela: sale.parcela,
          procesVerbalProcedura: sale.procesVerbalProcedura,
          procesVerbalFinalizare: sale.procesVerbalFinalizare,
          buyer: sale.buyer,
          registrationDate: sale.registrationDate,
          landRegistryNumber: sale.landRegistryNumber,
          sellerFirstNameLastName: sale.sellerFirstNameLastName,
          username: sale.username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleGetAllSales() {
    const getAllSalesUrl = `${baseURL}${getAllSales.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getAllSalesUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleGetSale(id) {
    const getSaleUrl = `${baseURL}${getSale.GET(id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;

    return await http
      .get(
        getSaleUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default ElectronicRegisterSalesOffer;
