<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <card-component
        :title="formCardTitle"
        icon="file-edit"
        class="tile is-child"
      >
        <sales-add
          @handleAddSale="handleAddSale"
          @handleEditSale="handleEditSale"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import ElectronicRegisterSale from "@/services/electronicRegisterSalesOffer.service";
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import SalesAdd from "../components/sales/sales-add.vue";

export default {
  name: "Sale",
  components: { TitleBar, CardComponent, SalesAdd },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    async handleAddSale(sale) {
      const { handleAddSale } = ElectronicRegisterSale;
      const response = await handleAddSale(sale);
      const { data, status } = response;
      if (status == "200") {
        this.$router.push({ path: "/Sales" });
      } else {
      }
    },
    async handleEditSale(sale) {
      const { handleEditSale } = ElectronicRegisterSale;
      const response = await handleEditSale(sale);
      this.$router.push({ path: "/Sales" });
    },
  },
  computed: {
    titleStack() {
      if (this.editMode) {
        return [
          "Registru Evidenta Vanzare Terenuri Agricole Extravilan",
          "Editare inregistrare",
        ];
      } else {
        return [
          "Registru Evidenta Vanzare Terenuri Agricole Extravilan",
          "Adaugare inregistrare",
        ];
      }
    },
    formCardTitle() {
      if (this.editMode) {
        return "Editare inregistrare";
      } else {
        return "Adaugare inregistrare";
      }
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    if (id) {
      this.editMode = true;
    } else {
    }
  },
};
</script>
