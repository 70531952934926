<template>
  <div>
    <ValidationObserver ref="form">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Data inregistrare</label>
            <div class="control">
              <b-datepicker :date-parser="parser" v-model="sale.registrationDate" locale="ro" @input="dateSelected()"
                :unselectable-days-of-week="[0, 6]">
              </b-datepicker>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Suprafata teren" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Suprafata teren (mp)'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.surface" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Suprafata teren" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Pretul de vanzare'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.price" step="0.01" min="0" max="10000000" type="number" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Categoria de folosinta" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Categoria de folosinta'" :message="errors[0]">
                <b-dropdown v-model="sale.category" aria-role="list">
                  <template #trigger>
                    <b-button type="is-primary" icon-right="menu-down">
                      Categoria de folosinta ({{ sale.category }})
                    </b-button>
                  </template>

                  <b-dropdown-item value="Arabil" aria-role="listitem">
                    <span>Arabil</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Vii" aria-role="listitem">
                    <span>Vii</span>
                  </b-dropdown-item>
                  <b-dropdown-item value="Curti" aria-role="listitem">
                    <span>Curti</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Tarla" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Tarla'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.tarla" type="text" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Parcela" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Parcela'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.parcela" type="text" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Nr. carte funciara" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Nr. carte funciara'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.landRegistryNumber" type="text" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <ValidationProvider name="Nume si  vanzator" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Nume si prenume vanzator'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.sellerFirstNameLastName" type="text" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Adresa vanzator" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Adresa vanzator'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.sellerAddress" type="text" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <ValidationProvider name="Cumparator" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :label="'Cumparator'" :message="errors[0]">
                <b-input class="user-name-input" v-model="sale.buyer" type="text" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Data proces verbal procedura</label>
            <div class="control">
              <b-datepicker :date-parser="parser" v-model="sale.procesVerbalProcedura" locale="ro"
                :unselectable-days-of-week="[0, 6]">
              </b-datepicker>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Data proces verbal finalizare</label>
            <div class="control">
              <b-datepicker :date-parser="parser" v-model="sale.procesVerbalFinalizare" locale="ro"
                :unselectable-days-of-week="[0, 6]">
              </b-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field grouped>
            <div class="control">
              <b-button type="is-primary" native-type="submit" @click="handleAddSale">Salvare</b-button>
            </div>
            <div class="control">
              <b-button type="is-danger is-outlined" @click.prevent="cancel()">Anulare</b-button>
            </div>
          </b-field>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { rawObject } from "@/helpers/utils";
import { pathOr } from "ramda";
import ElectronicRegisterSalesOffer from "@/services/electronicRegisterSalesOffer.service";
var moment = require("moment-business-days");
var momentBusinessDays = require("moment-business-days");
moment.updateLocale("ro", {
  holidays: [
    "01-01-2021",
    "02-01-2021",
    "24-01-2021",
    "30-04-2021",
    "01-05-2021",
    "02-05-2021",
    "03-05-2021",
    "01-06-2021",
    "20-06-2021",
    "21-06-2021",
    "15-08-2021",
    "30-11-2021",
    "01-12-2021",
    "25-12-2021",
    "26-12-2021",
    "01-01-2022",
    "02-01-2022",
    "24-01-2022",
    "22-04-2022",
    "24-04-2022",
    "25-04-2022",
    "01-05-2022",
    "01-06-2022",
    "12-06-2022",
    "13-06-2022",
    "15-08-2022",
    "30-11-2022",
    "01-12-2022",
    "25-12-2022",
    "26-12-2022",
    "01-01-2023",
    "02-01-2023",
    "24-01-2023",
    "14-04-2023",
    "16-04-2023",
    "17-04-2023",
    "01-05-2023",
    "01-06-2023",
    "04-06-2023",
    "05-06-2023",
    "15-08-2023",
    "30-11-2023",
    "01-12-2023",
    "25-12-2023",
    "26-12-2023",
    "01-01-2024",
    "02-01-2024",
    "06-01-2024",
    "07-01-2024",
    "24-01-2024",
    "01-05-2024",
    "03-05-2024",
    "04-05-2024",
    "05-05-2024",
    "06-05-2024",
    "01-06-2024",
    "23-06-2024",
    "24-06-2024",
    "15-08-2024",
    "30-11-2024",
    "01-12-2024",
    "25-12-2024",
    "26-12-2024"
  ],
  holidayFormat: "DD-MM-YYYY",
});

export default {
  name: "SalesAdd",
  data() {
    return {
      editMode: false,
      id: 0,
    };
  },
  methods: {
    parser(d) {
      return new Date(Date.parse(d));
    },
    dateSelected() {
      this.computeSaleDates(this.sale);
    },
    handleAddSale() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.sale.username = this.$auth.user.email;
        this.sale.registrationDate = new Date(
          this.sale.registrationDate.getTime() -
          this.sale.registrationDate.getTimezoneOffset() * 60000
        ).toJSON();

        this.sale.procesVerbalProcedura = new Date(
          this.sale.procesVerbalProcedura.getTime() -
          this.sale.procesVerbalProcedura.getTimezoneOffset() * 60000
        ).toJSON();

        this.sale.procesVerbalFinalizare = new Date(
          this.sale.procesVerbalFinalizare.getTime() -
          this.sale.procesVerbalFinalizare.getTimezoneOffset() * 60000
        ).toJSON();

        if (this.editMode) {
          this.$emit("handleEditSale", rawObject(this.sale));
        } else {
          this.$emit("handleAddSale", rawObject(this.sale));
        }
      });
    },
    async getSale(id) {
      const { handleGetSale } = ElectronicRegisterSalesOffer;
      const response = await handleGetSale(id);
      const { data } = response;
      return data;
    },
    computeSaleDates(sale) {
      var procesVerbalProcedura = momentBusinessDays(
        sale.registrationDate
      ).businessAdd(45)._d;
      sale.procesVerbalProcedura = procesVerbalProcedura;
      sale.procesVerbalFinalizare = moment(procesVerbalProcedura)
        .add(30, "days")
        .toDate();
    },
    resetForm() {
      for (let [key, value] of Object.entries(this.sale)) {
        this.sale[key] = null;
      }
      this.$refs.form.reset();
      if (this.editMode) {
        this.getSaleOffer(this.id).then((response) => {
          this.sale = response;
        });
      }
    },
    cancel() {
      this.$router.push({ path: "/Sales" });
    },
  },
  data() {
    return {
      sale: {
        registrationDate: null,
        sellerAddress: null,
        sellerFirstNameLastName: null,
        surface: null,
        category: null,
        price: null,
        tarla: null,
        parcela: null,
        landRegistryNumber: null,
        buyer: null,
        procesVerbalProcedura: null,
        procesVerbalFinalizare: null,
        username: null,
      },
    };
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    this.id = id;
    if (this.id) {
      this.editMode = true;
      this.getSale(id).then((response) => {
        this.sale = response;
        this.sale.registrationDate = new Date(this.sale.registrationDate);
        this.sale.procesVerbalProcedura = new Date(
          this.sale.procesVerbalProcedura
        );
        this.sale.procesVerbalFinalizare = new Date(
          this.sale.procesVerbalFinalizare
        );
      });
    } else {
      this.sale.registrationDate = new Date();
      this.computeSaleDates(this.sale);
    }
  },
};
</script>
