import { anyPass, has, isEmpty, isNil } from "ramda";

export const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;
export const stringToBoolean = (string) => {
  if (!string) {
    return false;
  }
  switch (string.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
    case null:
      return false;
    default:
      return Boolean(string);
  }
};
export const getNPProgress = () => {
  if (window) {
    if (window.hasOwnProperty("NProgress")) {
      const { NProgress } = window;
      return NProgress;
    }
  }
  return null;
};
export const debounce = (delay, fn) => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
};
export const globalComponentRegistration = (vueInstance, components) => {
  if (components && Array.isArray(components)) {
    components.forEach((component) => {
      const { key, compName } = component;
      vueInstance.component(key, compName);
    });
  }
};
export const hasProp = (prop, obj) => {
  return obj && obj.hasOwnProperty(prop);
};
export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};
export const truncateString = (str, length, ending) => {
  if (!str) {
    return;
  }
  str = str.replace(/<[^>]*>?/gm, "");
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const isNilOrEmpty = anyPass([isNil, isEmpty]);
export const openBlankPage = (pageURL) => {
  return !isNilOrEmpty(pageURL) ? window.open(pageURL, "_blank") : null;
};

export const getCurrentTitle = (self) => {
  let filteredRoutes = [];
  let { path } = self.$route;
  path = path && path.split("/").filter((q) => q);

  const {
    options: { routes },
  } = self.$router;

  if (isValidArray(routes) && isValidArray(path)) {
    filteredRoutes = routes
      .filter((q) => {
        return hasProp("prettyName", q) && q;
      })
      .find((q) => {
        return q.path.split("/").find((q) => path.includes(q) && q);
      });
    if (hasProp("prettyName", filteredRoutes)) {
      return filteredRoutes.prettyName;
    }
  }
  return "";
};

export const rawObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const scrollTo = (elementIdent) => {
  const el = document.querySelector(elementIdent);
  return el && el.scrollIntoView({ behavior: "smooth", block: "end" });
};
