var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Data inregistrare")]),_c('div',{staticClass:"control"},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","unselectable-days-of-week":[0, 6]},on:{"input":function($event){return _vm.dateSelected()}},model:{value:(_vm.sale.registrationDate),callback:function ($$v) {_vm.$set(_vm.sale, "registrationDate", $$v)},expression:"sale.registrationDate"}})],1)])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Suprafata teren","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Suprafata teren (mp)',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",model:{value:(_vm.sale.surface),callback:function ($$v) {_vm.$set(_vm.sale, "surface", $$v)},expression:"sale.surface"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Suprafata teren","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Pretul de vanzare',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",attrs:{"step":"0.01","min":"0","max":"10000000","type":"number"},model:{value:(_vm.sale.price),callback:function ($$v) {_vm.$set(_vm.sale, "price", $$v)},expression:"sale.price"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Categoria de folosinta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Categoria de folosinta',"message":errors[0]}},[_c('b-dropdown',{attrs:{"aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-right":"menu-down"}},[_vm._v(" Categoria de folosinta ("+_vm._s(_vm.sale.category)+") ")])]},proxy:true}],null,true),model:{value:(_vm.sale.category),callback:function ($$v) {_vm.$set(_vm.sale, "category", $$v)},expression:"sale.category"}},[_c('b-dropdown-item',{attrs:{"value":"Arabil","aria-role":"listitem"}},[_c('span',[_vm._v("Arabil")])]),_c('b-dropdown-item',{attrs:{"value":"Vii","aria-role":"listitem"}},[_c('span',[_vm._v("Vii")])]),_c('b-dropdown-item',{attrs:{"value":"Curti","aria-role":"listitem"}},[_c('span',[_vm._v("Curti")])])],1)],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Tarla","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Tarla',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",attrs:{"type":"text"},model:{value:(_vm.sale.tarla),callback:function ($$v) {_vm.$set(_vm.sale, "tarla", $$v)},expression:"sale.tarla"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Parcela","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Parcela',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",attrs:{"type":"text"},model:{value:(_vm.sale.parcela),callback:function ($$v) {_vm.$set(_vm.sale, "parcela", $$v)},expression:"sale.parcela"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Nr. carte funciara","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Nr. carte funciara',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",attrs:{"type":"text"},model:{value:(_vm.sale.landRegistryNumber),callback:function ($$v) {_vm.$set(_vm.sale, "landRegistryNumber", $$v)},expression:"sale.landRegistryNumber"}})],1)]}}])})],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Nume si  vanzator","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Nume si prenume vanzator',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",attrs:{"type":"text"},model:{value:(_vm.sale.sellerFirstNameLastName),callback:function ($$v) {_vm.$set(_vm.sale, "sellerFirstNameLastName", $$v)},expression:"sale.sellerFirstNameLastName"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Adresa vanzator","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Adresa vanzator',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",attrs:{"type":"text"},model:{value:(_vm.sale.sellerAddress),callback:function ($$v) {_vm.$set(_vm.sale, "sellerAddress", $$v)},expression:"sale.sellerAddress"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Cumparator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Cumparator',"message":errors[0]}},[_c('b-input',{staticClass:"user-name-input",attrs:{"type":"text"},model:{value:(_vm.sale.buyer),callback:function ($$v) {_vm.$set(_vm.sale, "buyer", $$v)},expression:"sale.buyer"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Data proces verbal procedura")]),_c('div',{staticClass:"control"},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","unselectable-days-of-week":[0, 6]},model:{value:(_vm.sale.procesVerbalProcedura),callback:function ($$v) {_vm.$set(_vm.sale, "procesVerbalProcedura", $$v)},expression:"sale.procesVerbalProcedura"}})],1)])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Data proces verbal finalizare")]),_c('div',{staticClass:"control"},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","unselectable-days-of-week":[0, 6]},model:{value:(_vm.sale.procesVerbalFinalizare),callback:function ($$v) {_vm.$set(_vm.sale, "procesVerbalFinalizare", $$v)},expression:"sale.procesVerbalFinalizare"}})],1)])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"grouped":""}},[_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"},on:{"click":_vm.handleAddSale}},[_vm._v("Salvare")])],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-danger is-outlined"},on:{"click":function($event){$event.preventDefault();return _vm.cancel()}}},[_vm._v("Anulare")])],1)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }